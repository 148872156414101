<template>
  <div>
    <b-modal title="쿠폰 발행 신청" ref="modal_view" :hide-footer="true" @shown="init">
      <b-form-group id="fieldset-1">
        <b-input-group prepend="상품명">
          <b-form-input value="30일 구독권"></b-form-input>
        </b-input-group>

        <b-input-group prepend="수  량" class="mt-2">
          <b-form-spinbutton v-model="pub_count" min="50" max="700" step="50"></b-form-spinbutton>
        </b-input-group>

        <br /><br />
        <b-button @click="close">취소</b-button>
        <b-button variant="danger" @click="submit" class="ml-2">신청</b-button>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { plusup_insert } from '../../api/site'

export default {
  data() {
    return {
      pub_count: 50,
      pr_sn: 19
    }
  },
  methods: {
    close() {
      this.$refs['modal_view'].hide()
    },
    async submit() {
      const response = await plusup_insert(this.pr_sn, this.pub_count)
      if (response.code === '0000') {
        alert('신청했습니다.')
        this.$emit('refresh')
        this.close()
      } else {
        alert(response.message)
      }
    },
    init() {
      this.pub_count = 50
    }
  }
}
</script>
