<template>
  <div>
    <title-nav :title="'쿠폰현황'" :nav="'제휴관리 / 쿠폰 현황'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <b-row>
            <b-col>
              <b-input-group size="sm">
                <b-input-group-append>
                  <datepicker
                    id="from-datepicker-util"
                    v-model="start_date"
                    v-on:selected="changeStartDate"
                    name="start_date"
                    :value="start_date.date"
                    :format="start_format"
                    placeholder="시작일"
                    input-class="form-control custom-select-sm"
                  />
                  ~
                  <datepicker
                    id="to-datepicker-util"
                    v-model="end_date"
                    v-on:selected="changeEndDate"
                    name="end_date"
                    :value="end_date.date"
                    :format="end_format"
                    placeholder="종료일"
                    input-class="form-control custom-select-sm"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col
              >발행 합계 <b>{{ total_pub_cnt | commaFilter }}</b></b-col
            >
            <b-col>
              <div class="d-flex flex-row-reverse">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="$refs['reqModal'].$refs.modal_view.show()"
                >
                  쿠폰 신청
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="card-body p-0">
          <b-table
            striped
            hover
            bordered
            class="table-sm text-sm-center"
            :borderless="false"
            :fixed="true"
            :no-border-collapse="false"
            :head-variant="headVariant"
            :items="result_list"
            :fields="fields"
            :busy="isBusy"
            show-empty
            stickyHeader
            responsivee
          >
            <template v-slot:cell(user_sn)="data">
              <router-link
                :to="{ name: 'detail', params: { usersn: data.value } }"
                variant="link"
                >{{ data.value }}</router-link
              >
            </template>
            <template v-slot:cell(pub_count)="data">
              <template v-if="data.item.pub_stat_cd == 'OK'">
                <b-button variant="link" @click="download(data.item.pub_sn)" style="padding:0">
                  {{ data.value }}
                </b-button>
              </template>
              <template v-else>
                {{ data.value }}
              </template>
            </template>
            <template v-slot:cell(pub_stat_cd)="data">
              <template v-if="data.value == 'OK'">승인완료</template>
              <template v-else-if="data.value == 'CANCEL'">요청취소</template>
              <template v-else>
                <b-button
                  variant="link"
                  @click="openModal(data.item.pub_sn)"
                  style="padding:0"
                  v-if="getUser.ADMIN_LEVEL === 'SA'"
                >
                  요청중
                </b-button>
                <template v-else>요청중</template>
              </template>
            </template>
            <template v-slot:cell(use_count)="data"
              ><template v-if="data.item.pub_stat_cd == 'OK'"
                ><b-button variant="link" @click="detail(data.item)" style="padding:0">
                  {{ data.value }}
                </b-button></template
              ><template v-else>
                {{ data.value }}
              </template></template
            >
          </b-table>
        </div>
        <div class="card-footer clearfix">
          <b-pagination
            v-model="curPage"
            :total-rows="total_cnt"
            :per-page="rowPerPage"
            @change="setPage"
            align="right"
          ></b-pagination>
        </div>
      </div>

      <div class="card" v-if="getUser.ADMIN_LEVEL === 'SA'">
        <div class="card-header">
          <b-row>
            <b-col></b-col>
            <b-col cols="5">
              <b-input-group size="sm">
                <b-form-select
                  slot="prepend"
                  type="search"
                  v-model="searchType"
                  :options="searchOptions"
                  size="sm"
                  class="mr-3"
                ></b-form-select>
                <b-form-input
                  v-model="searchText"
                  placeholder="Search"
                  @keyup.enter="detail"
                ></b-form-input>
                <b-input-group-append is-text size="sm">
                  <b-icon icon="search" @click="detail"></b-icon>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>
        <div class="card-body p-0">
          <b-table
            striped
            hover
            bordered
            class="table-sm text-sm-center"
            :borderless="false"
            :fixed="true"
            :no-border-collapse="false"
            :head-variant="headVariant"
            :items="result_list_sub"
            :fields="fields_sub"
            :busy="isBusy"
            show-empty
            stickyHeader
            responsivee
          >
          </b-table>
        </div>
        <div class="card-footer clearfix">
          <b-pagination
            v-model="curPage_sub"
            :total-rows="total_cnt_sub"
            :per-page="rowPerPage"
            @change="setPage_sub"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </b-container>
    <!-- 환불 상태 변경 모달 -->
    <plusup-req-modal-view ref="reqModal" @refresh="init" />
    <plusup-pub-modal-view ref="pubModal" @refresh="init" :pub_sn="selectedPubSn" />
  </div>
</template>
<script>
import { plusup_list, plusup_download, plusup_detail } from '@/api/site'
import { mapGetters } from 'vuex'
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import PlusupReqModalView from './PlusUpReq'
import PlusupPubModalView from './PlusUpPubDo'

var now = new Date()
var monthFirstDate = new Date(now.getUTCFullYear(), now.getMonth(), 1)
var monthLastDate = new Date(now.getUTCFullYear(), now.getMonth() + 1, 0)

export default {
  name: 'PlusUp',
  components: {
    Datepicker,
    PlusupReqModalView,
    PlusupPubModalView
  },
  data() {
    return {
      start_date: this.$moment(monthFirstDate).format('YYYY/MM/DD'),
      end_date: this.$moment(monthLastDate).format('YYYY/MM/DD'),
      start_format: 'yyyy/MM/dd',
      end_format: 'yyyy/MM/dd',
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      isBusy: false,
      noCollapse: false,
      headVariant: 'dark',
      total_cnt: 0,
      total_pub_cnt: 0,
      result_list: [],
      result_list_sub: [],
      total_cnt_sub: 0,
      startDate: '',
      endDate: '',
      curPage: 1,
      rowPerPage: 20,
      selectedPubSn: 0,
      curPage_sub: 1,
      records_sub: 0,
      searchType: '',
      searchText: '',
      searchOptions: [
        { value: '', text: '선택' },
        { value: 'user_sn', text: '회원번호' },
        { value: 'name', text: '이름' },
        { value: 'cp_no', text: '쿠폰번호' }
      ],
      selectedItem: null,
      fields: [
        {
          key: 'reg_dt',
          label: '요청일시',
          colType: 'span'
        },
        {
          key: 'pub_dt',
          label: '처리일시',
          colType: 'span'
        },
        {
          key: 'title',
          label: '쿠폰명',
          colType: 'span'
        },
        {
          key: 'pub_count',
          label: '개수',
          colType: 'span'
        },
        {
          key: 'pub_stat_cd',
          label: '상태',
          colType: 'span'
        }
      ],
      fields_sub: [
        {
          key: 'cp_no',
          label: '쿠폰번호',
          colType: 'span'
        },
        {
          key: 'use_dt',
          label: '쿠폰사용일',
          colType: 'span'
        },
        {
          key: 'user_sn',
          label: '회원번호',
          colType: 'span'
        },
        {
          key: 'user_name',
          label: '회원이름',
          colType: 'span'
        },
        {
          key: 'start_date',
          label: '시작일',
          colType: 'span'
        },
        {
          key: 'end_date',
          label: '종료일',
          colType: 'span'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('user', ['getUser'])
    // getLevel() {
    //   return this.$store.getters['user/getUserLevel']
    // }
  },
  // watch: {
  //   getLevel: function(val) {
  //     console.log('watched: ', val)
  //     return val
  //   }
  // },
  methods: {
    reset() {
      this.result_list = []
      this.result_list_sub = []
      this.total_cnt = this.total_cnt_sub = 0
      this.selectedPubSn = null
      this.selectedItem = null
    },
    changeStartDate(val) {
      this.start_date = this.$moment(val).format('YYYY/MM/DD')
      this.curPage = 1
      this.reset()
      this.init(this.start_date, this.end_date)
    },
    changeEndDate(val) {
      this.end_date = this.$moment(val).format('YYYY/MM/DD')
      this.curPage = 1
      this.reset()
      this.init(this.start_date, this.end_date)
    },
    setPage(page) {
      this.curPage = page
      this.reset()
      this.init(this.start_date, this.end_date)
    },
    setPage_sub(page) {
      this.curPage_sub = page
      this.result_list_sub = []
      this.detail()
    },
    async init(startDate = this.start_date, endDate = this.end_date) {
      try {
        startDate = this.$moment(startDate).format('YYYYMMDD')
        endDate = this.$moment(endDate).format('YYYYMMDD')
        console.log(startDate, endDate)
        this.total_cnt = 0
        const resp = await plusup_list(startDate, endDate, this.curPage, this.rowPerPage)
        if (resp.code === '0000') {
          // console.log(resp.data)
          this.result_list = resp.data.list
          this.total_cnt = resp.data.totCnt
          this.total_pub_cnt = resp.data.totPubCnt
        } else {
          alert(`error:${resp.code}`)
        }
      } catch (error) {
        alert(`error:${error}`)
      }
    },
    async openModal(pub_sn) {
      this.selectedPubSn = pub_sn
      this.$refs['pubModal'].$refs.modal_view.show()
    },
    async download(pub_sn) {
      plusup_download(pub_sn)
    },
    updateTable() {
      if (this.getUser.ADMIN_LEVEL === 'SA') {
        this.rowPerPage = 10
        this.fields.splice(4, 0, {
          key: 'use_count',
          label: '사용개수',
          colType: 'span'
        })
      }
    },
    async detail(item) {
      if (item && item.pub_sn) {
        this.selectedItem = item
        this.result_list_sub = []
        this.total_cnt_sub = 0
        this.searchType = ''
        this.searchText = ''
      }
      if (this.selectedItem.pub_sn) {
        const resp = await plusup_detail(
          this.selectedItem.pub_sn,
          this.curPage_sub,
          this.rowPerPage,
          this.searchType,
          this.searchText
        )
        if (resp.code === '0000') {
          this.result_list_sub = resp.data.list
          this.total_cnt_sub = resp.data.totCnt
        } else {
          alert(`error:${resp.code}`)
        }
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.updateTable()
    }, 1000)
    this.init()
  }
}
</script>
