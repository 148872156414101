import { axiosGet, axiosPut, axiosPost, axiosDownload } from './config'

export const plusup_list = (start_date, end_date, page_no = 1, page_size = 30) => {
  var url = `site/plusup?curPage=${page_no}&rowPerPage=${page_size}&startDate=${start_date}&endDate=${end_date}`
  return axiosGet(url)
}

export const plusup_insert = async (pr_sn, pub_count) => {
  return await axiosPut('site/plusup', {
    pr_sn,
    pub_count
  })
}

export const plusup_update = async (pub_stat_cd, pub_sn, cp_name, cp_start_dt, cp_end_dt) => {
  return await axiosPost('site/plusup', {
    pub_stat_cd,
    pub_sn,
    cp_name,
    cp_start_dt,
    cp_end_dt
  })
}

export const plusup_download = pub_sn => {
  axiosDownload(`site/plusup/download/${pub_sn}`).then(function(response) {
    var blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
    var blobURL = window.URL.createObjectURL(blob)
    var tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', `coupon_${pub_sn}.xlsx`)
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  })
}

export const plusup_detail = (
  pub_sn,
  page_no = 1,
  page_size = 30,
  searchType = '',
  searchText = ''
) => {
  var url = `site/plusup/${pub_sn}?curPage=${page_no}&rowPerPage=${page_size}&searchType=${searchType}&searchText=${searchText}`
  return axiosGet(url)
}
